import { Vue } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
	name: 'login',  
	path: '/login', 
	component:()=>import('@/view/login/'),
	meta: {
		title: '员工登录',
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
  },
  {
	name: 'employee',
	path: '/employee',
	component:()=>import('@/view/employee'),
	meta: {
		title: '员工列表',
		auth:true,
		roles: ['管理员','行政','总经理'],
	},
  },
  {
	name: 'boundsearch',
	path: '/boundsearch',
	component:()=>import('@/view/boundsearch'),
	meta: {
		title: '库存查询',
		auth:true,
		roles: ['管理员','仓库','总经理'],
	},
  },
  {
	name: 'shelvessearch',
	path: '/shelvessearch',
	component:()=>import('@/view/shelvessearch'),
	meta: {
		title: '货架查询',
		auth:true,
		roles: ['管理员','仓库','总经理'],
	},
  },
  {
	name: 'course',
	path: '/course',
	component:()=>import('@/view/course'),
	meta: {
		title: '出入库记录',
		auth:true,
		roles: ['管理员','仓库','总经理'],
	},
  },
  {
	name: 'vehicle',
	path: '/vehicle',
	component:()=>import('@/view/vehicle'),
	meta: {
		title: '车辆',
		auth:true,
		roles: ['管理员','仓库','总经理'],
	},
  },
  {
	name: 'attend',
	path: '/attend',
	component:()=>import('@/view/attend'),
	meta: {
		title: '考勤',
		auth:true,
		roles: ['管理员','财务','行政','普通员工','仓库','开单'],
	},
  },
  {
	name: 'graspmanagement',
	path: '/graspmanagement',
	component:()=>import('@/view/graspmanagement'),
	meta: {
		title: '经营历程',
		auth:true,
		roles: ['管理员','总经理','开单'],
	},
  },
  {
	name: 'inventory',
	path: '/inventory',
	component:()=>import('@/view/inventory'),
	meta: {
		title: '库存状况',
		auth:true,
		roles: ['管理员','总经理','开单'],
	},
  },
  {
	name: 'graspoldarap',
	path: '/graspoldarap',
	component:()=>import('@/view/graspoldarap'),
	meta: {
		title: '应收应付（旧）',
		auth:true,
		roles: ['管理员','总经理'],
	},
  },
  {
	name: 'grasparap',
	path: '/grasparap',
	component:()=>import('@/view/grasparap'),
	meta: {
		title: '应收应付',
		auth:true,
		roles: ['管理员','总经理','开单'],
	},
  },
  {
    path: '/',
    component: () => import('@/view/Tabbar/'),
	meta:{ 
		auth:true,
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
	children:[
  {
    name: 'main',
    path: '/main',
    component: () => import('@/view/main/'),
	meta: {
		title: '控制台',
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
  },
  {
    name: 'goods',
    path: '/addbook',
    component: () => import('@/view/addbook/'),
	meta: {
		title: '通讯录',
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
  },
  {
    name: 'my',
    path: '/my',
    component: () => import('@/view/my/'),
	meta: {
		title: '我的',
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
  },
	]
  },
  {
	name: '404',
	path: '/404',
	component:()=>import('@/view/404'),
	meta: {
		title: '权限不足',
		roles: ['管理员','财务','行政','普通员工','仓库','总经理','开单'],
	},
  },

];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
    }
  if (to.path === '/login') return next()
  const userStr = localStorage.getItem('user')
  if (!userStr) return next('/login')
  const roleStr = localStorage.getItem('roles')
  if(to.meta.roles.includes(roleStr)){
	  next()
  }else{
	  return next({path:"/404"}) //跳到404页面
  }

});

export { router };
